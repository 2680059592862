<template>
  <div class="test">
    <portlet>
      <template v-slot:body></template>
    </portlet>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";

  export default {
    name: "index",
    components: {Portlet},
    mounted() {},
    computed: {

    },
    created() {

    },
    data(){
      return {
      };
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>
